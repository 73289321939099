// Firebase realtime data
export const usePlansData = () => useState<FsbdPlan[]>('plansData', () => [])

export const useProductsData = () => useState<FsbdProduct[]>('productsData', () => [])

export const useCompaniesData = () => useState<FsbdCompany[]>('companiesData', () => [])

export const useRolesData = () => useState<FsbdRoles[]>('rolesData', () => [])

export const useUsersData = () => useState<FsbdUser[]>('usersData', () => [])

export const useUserData = () => useState<FsbdUser|null>('userData', () => null)

export const useTypologiesData = () => useState<FsbdTypologies[]>('typologiesData', () => [])

// Static Selectors data (genders, ages, territories)
export const useSelectorsData = () => useState<FsbdSelectors>('selectorsData')
